import { FormProvider, useForm, Controller } from 'react-hook-form';
import Autocomplete from '../Autocomplete';
import { useSearchBar } from 'src/talons/useSearchBar';
import wrapUseSearchBars from 'src/targets/wrapUseSearchBars';
import debounce from 'lodash/debounce';
import './style.scss';

const GlobalSearch = ({ handleSearchClose }) => {
  const talonProps = wrapUseSearchBars(useSearchBar)({});
  const methods = useForm({ mode: 'onChange' });
  const { setValue, register, handleSubmit, control } = methods;

  const {
    handleChange,
    handleFocus,
    handleQuerySubmit,
    setInputSearchValue,
    valid,
    isAutoCompleteOpen,
    containerRef,
    setIsAutoCompleteOpen,
  } = talonProps;

  const onSubmitQuery = data => handleQuerySubmit(data);

  const handleInputChange = e => handleChange(e);

  const searchClose = () => {
    handleSearchClose();
    setValue('search_query', '');
  };

  return (
    <div className="mt-3 w-full relative search-input-container">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitQuery)}>
          <Controller
            name="search_query"
            control={control}
            render={({ field }) => {
              return (
                <div className="form-field w-full relative">
                  <input
                    type="text"
                    className="w-full"
                    id="search_query"
                    placeholder="Search here"
                    autoComplete="off"
                    name="search_query"
                    {...register('search_query')}
                    onChange={debounce(e => {
                      handleInputChange(e);
                      field.onChange(e);
                    }, 1000)}
                    onFocus={handleFocus}
                  />
                  <button
                    type="button"
                    className="absolute top-[48%] right-[-1%] px-4 text-[30px] text-black leading-[0]"
                    onMouseDown={searchClose}
                  >
                    ×
                  </button>
                </div>
              );
            }}
          />
        </form>
        <div ref={containerRef}>
          <Autocomplete
            valid={valid}
            visible={isAutoCompleteOpen}
            setInputSearchValue={setInputSearchValue}
            setIsAutoCompleteOpen={setIsAutoCompleteOpen}
          />
        </div>
      </FormProvider>
    </div>
  );
};

export default GlobalSearch;
