import React, { Fragment, useEffect, useRef, useState } from 'react';
import { bool, func, string } from 'prop-types';
import Items from './items';
import { useAmXsearchContext } from 'src/context';
import { useItems } from 'src/talons/useItems';
import mergeOperations from 'packages/framework/util/shallowMerge';
import LoadingIndicator from 'packages/framework/components/LoadingIndicator';
import defaultOperations from '../../queries/getSearch.gql';
import { useFormContext, useWatch } from 'react-hook-form';
import styles from './resultsBlock.module.scss'

const ResultsBlock = props => {
  const [isLoading, setIsLoading] = useState(false);
  const isInitialLoad = useRef(true);
  const { valid, setInputSearchValue, setIsAutoCompleteOpen } = props;
  const { control } = useFormContext();
  const value = useWatch({
    control,
    name: 'search_query',
  });

  const { storeConfig } = useAmXsearchContext();
  const operations = mergeOperations(defaultOperations, props.operations);
  const { GET_SEARCH } = operations;
  const { data, loading } = useItems({
    inputText: value,
    query: GET_SEARCH,
  });

  useEffect(() => {
    if(isInitialLoad?.current && loading) {
      setIsLoading(loading)
    } else {
      setIsLoading(false)
      isInitialLoad.current = false
    }
  },[loading])

  const list = Object.keys(data).map((item, index) => (
    <Items
      setInputSearchValue={setInputSearchValue}
      setIsAutoCompleteOpen={setIsAutoCompleteOpen}
      storeConfig={storeConfig}
      item={data[item]}
      valid={valid}
      key={data[item] + index}
    />
  ));

  return (
    <Fragment>
      {isLoading ? (
        <span className={`${styles.loader} w-full flex justify-center`}>
          <LoadingIndicator />
        </span>
      ) : (
        list
      )}
    </Fragment>
  );
};

export default ResultsBlock;

ResultsBlock.propTypes = {
  valid: bool,
  setInputSearchValue: func,
  searchedValue: string,
  setIsAutoCompleteOpen: func,
};
